<script setup>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';
import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue';
import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head title="Log in" />
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col col-md-6">
                <JetAuthenticationCard>
                    <template #logo>
                        <div class="text-center">
                            <JetAuthenticationCardLogo />
                        </div>
                    </template>

                    <JetValidationErrors class="mb-4" />

                    <div v-if="status" class="mb-4">
                        {{ status }}
                    </div>

                    <form @submit.prevent="submit">
                        <div class="form-floating mb-3">
                            <JetInput
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="form-control"
                                placeholder=" "
                                required
                                autofocus
                            />
                            <JetLabel for="email" value="E-Mail" />
                        </div>

                        <div class="form-floating mb-3">
                            <JetInput
                                id="password"
                                v-model="form.password"
                                type="password"
                                class="form-control"
                                placeholder=" "
                                required
                                autocomplete="current-password"
                            />
                            <JetLabel for="password" value="Passwort" />
                        </div>

                        <div class="form-check mb-3 d-none">
                            <JetCheckbox class="form-check-input" v-model:checked="form.remember" name="remember" id="remember" />
                            <label class="form-check-label" for="remember">
                                Remember me
                            </label>
                        </div>

                        <div class="d-flex align-items-center justify-content-end">
                            <Link v-if="canResetPassword" :href="route('password.request')">
                                Passwort vergessen?
                            </Link>

                            <JetButton class="btn primary" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                Anmelden
                            </JetButton>
                        </div>
                    </form>
                </JetAuthenticationCard>
            </div>
        </div>

    </div>


</template>
<style scoped lang="scss">
label
{
    font-size: 16px;
}
</style>
