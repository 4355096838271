<template>
    <div>
        <Head title="Edit User"/>
        <h1>
            <Link :href="route('insights.myaccount')">My Account</Link>
        </h1>
        <div class="rounded shadow p-3">
            <form @submit.prevent="update">
                <div class="col">
                    <div class="row">
                        <div class="col-12">
                            <p>Main data</p>
                        </div>
                        <div class="col-12">
                            <text-input v-model="form.name" :error="form.errors.name" class="mb-3" label="Name"/>
                            <text-input v-model="user.email" class="mb-3" label="Email" disabled="true"/>
                            <text-input v-model="form.password" :error="form.errors.password" class="mb-3" type="password" autocomplete="new-password" label="Password"/>
                            <text-input v-model="form.passwordRepeat" :error="form.errors.passwordRepeat" class="mb-3" type="password" label="Repeat password"/>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <loading-button :loading="form.processing" class="btn primary action" type="submit">Update</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3';
import Layout from '@/Shared/Layout';
import FileInput from '@/Shared/FileInput';
import TextInput from '@/Shared/TextInput';
import SelectInput from '@/Shared/SelectInput';
import LoadingButton from '@/Shared/LoadingButton';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import BackendLayout from '@/Layouts/BackendLayout.vue';

export default {
    components: {
        FileInput,
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        JetCheckbox,
    },
    layout: Layout,
    //remember: 'form',
    props: {
        offerers: Array,
        roles: Array,
        user: Object
    },
    data()
    {
        return {
            form: this.$inertia.form(
                {
                    _method: 'put',
                    name: this.user.name,
                    email: this.user.email,
                    password: null,
                    passwordRepeat: null,
                    offerers: this.user.offerers,
                    roles: this.user.roles,
                },
            ),
        };
    },
    mounted()
    {
        console.log(this);
    },
    methods: {
        update()
        {
            this.form.post(route('insights.myaccount.update'));
        },
    },
};
</script>
