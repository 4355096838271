<template>
    <div class="row align-items-center mb-1" v-for="item in itemData">
        <div class="col label">{{item.name}}</div>
        <div class="col bar position-relative">
            <div class="bar-background" :style="{backgroundColor: color, width: item.pct * 100 + '%'}"></div>
            <span class="position-relative">{{item.count}} ({{item.pctReadable}})</span>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        items: Array,
        radius: {
            type: Number,
            default: 200,
        },
        backgroundColor: {
            type: String,
            default: 'transparent'
        },
        color: {
            type: String,
            default: '#009dff'
        },
    },
    data()
    {
      return {
          opacityAdjustment: 0
      }
    },
    computed: {
        countTotal()
        {			
            let count = 0;
            for(const item of this.items)
            {
                count += item.count;
            }
            return count;
        },
        itemData()
        {
            const items = this.items;
            //let rotation = 0;
            let currentHighestPct = 0;

            items.map(
                (item) =>
                {
					var pct = item.count / this.countTotal;
					var totalItems = this.countTotal;
				
					if(typeof item.total != 'undefined' && item.total>0){
						pct = item.count / item.total;
						totalItems = this.countTotal;
					}
					
                    if(pct > currentHighestPct)
                    {
                        currentHighestPct = pct;
                    }
                    //item['strokeDashArray'] = pct * this.radius * this.PI + ' ' + this.radius * this.PI;
                    item['pct'] = pct;
                    //item['rotation'] = rotation;
                    item['pctReadable'] = Math.round(pct * 10000) / 100 + '%';
                    //rotation += 360 * pct;
                },
            );
            this.opacityAdjustment = 1 - currentHighestPct;
            return items;
        },
    },
    created()
    {
        this.PI = 3.14159265359;
        //this.opacityAdjustment = 0;
    },
    mounted()
    {
        //console.log(this.itemData, this.opacityAdjustment);
    },
};
</script>
<style scoped lang="scss">
.label
{
    flex-grow: 0;
    flex-basis: 500px;
}
.bar
{
    background: rgba(0, 157, 255, .1);
    .bar-background
    {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}
</style>
