require('./bootstrap');

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import 'bootstrap';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'PAYVED Insights';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => require(`./Pages/Insights/${name}.vue`),
	setup({el, App, props, plugin})
	{
		return createApp({render: () => h(App, props)})
		.use(plugin)
		.mixin({methods: { route } })
		.mount(el);
	},
});

InertiaProgress.init(
    {
        showSpinner: true,
        color: '#009DFF'
    },
);
