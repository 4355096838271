<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        leads: Object,
        limit: Number,
        filters: Object,
        offerers: Object,
        industries: Object,
        sources: Object,
        platforms: Object,
        processing: Object,
		specialCriterias: Object,
		leadStates: Object,
		total: Number,
        stats: Object
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                offerers: this.filters?.offerers,
                industries: this.filters?.industries,
                sources: this.filters?.sources,
                platforms: this.filters?.platforms,
                processing: this.filters?.processing,
                campaignId: this.filters?.campaignId,
                specialCriterias: this.filters?.specialCriterias,
                leadStates: this.filters?.leadStates
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.offerers.length == 0) this.options.offerers = [-1];
					if(this.options.industries.length == 0) this.options.industries = [-1];
					if(this.options.sources.length == 0) this.options.sources = [-1];
					if(this.options.platforms.length == 0) this.options.platforms = [-1];
					if(this.options.processing.length == 0) this.options.processing = [-1];
					if(this.options.campaignId.length == 0) this.options.campaignId = 1;
					if(this.options.specialCriterias.length == 0) this.options.specialCriterias = [-1];
					if(this.options.leadStates.length == 0) this.options.leadStates = [-1];
                    this.$inertia.get(route('insights.leads'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        },
        statsSorted()
        {
            const leadStatus = Object.values(this.stats.leadStatus);
            const leadStatusProcessing = Object.values(this.stats.leadStatusProcessing);
            const leadStatusFailure = Object.values(this.stats.leadStatusFailure);
            const leadStatusLost = Object.values(this.stats.leadStatusLost);
            return {leadStatus: leadStatus, leadStatusProcessing: leadStatusProcessing, leadStatusFailure: leadStatusFailure, leadStatusLost: leadStatusLost};
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
        exportLeads()
        {
            window.location.href = route('insights.lead.export');
        },
    },
};
</script>

<template>
    <Head title="Marketing Qualified Leads"/>
	<h1>Marketing Qualified Leads</h1>
	
    <div class="filters">
        <div class="row">
			<div class="col-12" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
				<div class="campaign-head">
					<label for="campaignSelection">Kampagne:</label>
					<select v-model="options.campaignId" class="form-select mt-1 w-full" id="campaignSelection">
						<option value="1">PAYVED Default</option>
						<option value="3">PAYVED Acceptance Initiative</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3" v-if="offerers.length > 1">
        <div class="col-12">
            <span>Filter nach Zahlungsanbieter:</span>
        </div>
        <div class="col-auto" v-for="offerer in offerers">
            <div class="form-check">
				<input class="form-check-input" type="checkbox" :id="'offerer-' + offerer.id" name="offerers" :value="offerer.id" v-model="options.offerers"/>
                <label class="form-check-label" :for="'offerer-' + offerer.id">{{offerer.name}}</label>
            </div>
        </div>

    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Quelle:</span>
        </div>
        <div class="col-auto" v-for="source in sources">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'source-' + source.id" name="sources" :value="source.id" v-model="options.sources"/>
                <label class="form-check-label" :for="'source-' + source.id">{{source.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Werbeplattform:</span>
        </div>
        <div class="col-auto" v-for="platform in platforms">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'platform-' + platform.id" name="platforms" :value="platform.id" v-model="options.platforms"/>
                <label class="form-check-label" :for="'platform-' + platform.id">{{platform.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Kontaktart:</span>
        </div>
        <div class="col-auto" v-for="proc in processing">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'proc-' + proc.id" name="processing" :value="proc.id" v-model="options.processing"/>
                <label class="form-check-label" :for="'proc-' + proc.id">{{proc.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Branche:</span>
        </div>
        <div class="col-auto" v-for="industry in industries">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'industry-' + industry.id" name="industries" :value="industry.id" v-model="options.industries"/>
                <label class="form-check-label" :for="'industry-' + industry.id">{{industry.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Status:</span>
        </div>
        <div class="col-auto" v-for="leadState in leadStates">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'leadState-' + leadState.id" name="leadStates" :value="leadState.id" v-model="options.leadStates"/>
                <label class="form-check-label" :for="'leadState-' + leadState.id">{{leadState.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Sonstige Filter:</span>
        </div>
		<div class="col-auto" v-for="specialCriteria in specialCriterias">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'specialCriteria-' + specialCriteria.id" name="specialCriterias" :value="specialCriteria.id" v-model="options.specialCriterias"/>
                <label class="form-check-label" :for="'specialCriteria-' + specialCriteria.id">{{specialCriteria.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-4">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl der MQLs:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row justify-content-center">
				<div class="col-auto">
					<button class="btn fs-6" @click="exportLeads()">
						<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="48"><path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"/></svg>
						Export
					</button>
				</div>
			</div>
        </div>
		<div class="col-4">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="leads.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Erstellt am</th>
					<th>Vorname</th>
					<th>Nachname</th>
					<th>Firma</th>
					<th>Telefon</th>
					<th>E-Mail</th>
					<th>Kontaktart</th>
					<th>Anbieter</th>
					<th>Angebot</th>
					<th>Quelle</th>
					<th>Werbeplattform</th>
					<th>Branche</th>
					<th>Status</th>
					<th>Anmerkungen</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="lead in leads.data">
					<td><span :title="'ID: '+lead.id">{{ formatDate(lead.created_at) }}</span></td>
					<td>{{lead.firstname}}</td>
					<td>{{lead.lastname}}</td>
					<td>{{lead.company}}</td>
					<td>{{lead.phone}}</td>
					<td>{{lead.email}}</td>
					<td>{{lead.processingName}}</td>
					<td>{{lead.offerer_name}}</td>
					<td>{{lead.offer_name}}</td>
					<td>{{lead.source_name}}</td>
					<td>{{lead.platform}}</td>
					<td>{{lead.industry_name}}</td>
					<td><span :title="'Stand: '+formatDate(lead.lead_status_modified_at)">{{lead.lead_state_name}}</span></td>
					<td>{{lead.lead_status_notes}}</td>
				</tr>
            </tbody>
        </table>
    </div>

    <h3 v-else>{{noDataAvailable}}</h3>
    <nav v-if="leads.links.length > 3">
        <ul class="pagination flex-wrap">
            <template v-for="link in leads.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active, 'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
	<br/>
	<br/>
	<h2>Auswertungen Lead-Status</h2>
	<br/>
    <div class="row justify-content-center">
        <div class="col-4">
			<p><strong>Lead-Status</strong></p>
            <pie-chart :items="statsSorted.leadStatus"></pie-chart>
        </div>
		<div class="col-8">
			<p><strong>Leads </strong></p>
            <bar-chart :items="statsSorted.leadStatus"></bar-chart>
        </div>
    </div>
	<br/>
	<div class="row justify-content-center">
        <div class="col-12">
			<p><strong>Leads in Bearbeitung</strong></p>
            <bar-chart :items="statsSorted.leadStatusProcessing"></bar-chart>
        </div>
	</div>
	<br/>
	<div class="row justify-content-center">
        <div class="col-12">
			<p><strong>Leads fehlerhaft</strong></p>
            <bar-chart :items="statsSorted.leadStatusFailure"></bar-chart>
        </div>
	</div>
	<br/>
	<div class="row justify-content-center">
        <div class="col-12">
			<p><strong>Leads verloren</strong></p>
            <bar-chart :items="statsSorted.leadStatusLost"></bar-chart>
        </div>
	</div>
	<br/>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";



</style>
