<template>
    <div class="row align-items-center">
        <div class="col-sm-6">
            <div class="chart-wrapper">
                <div class="pie-chart">
                    <svg :height="radius * 2" :width="radius * 2" :viewBox="'0 0 ' + radius * 2 + ' ' + radius * 2">
                        <circle :r="radius" :cx="radius" :cy="radius" :fill="backgroundColor"/>
                        <g class="slices" transform="rotate(-90)" transform-origin="center">
                            <circle
                                v-for="item in itemData"
                                :r="radius / 2"
                                :cx="radius"
                                :cy="radius"
                                fill="transparent"
                                :stroke="color"
                                :stroke-opacity="item.pct + opacityAdjustment"
                                :stroke-width="radius"
                                :stroke-dasharray="item.strokeDashArray"
                                transform-origin="center"
                                :transform="`rotate(${item.rotation})`"
                            />
                        </g>
                        <g class="lines">
                            <line
                                v-for="item in itemData"
                                :x1="radius"
                                :y1="radius"
                                :x2="radius"
                                y2="0"
                                :stroke="dividerColor"
                                :stroke-width="dividerWidth"
                                transform-origin="center"
                                :transform="`rotate(${item.rotation})`"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <ul class="legend">
                <li v-for="item in itemData"><span class="dot" :style="{opacity: item.pct + opacityAdjustment, backgroundColor: color}"></span>{{item.pctReadable}} {{item.name}} ({{item.count}})</li>
            </ul>
        </div>

    </div>

</template>

<script>
export default {
    props: {
        items: Array,
        radius: {
            type: Number,
            default: 200,
        },
        backgroundColor: {
            type: String,
            default: 'transparent'
        },
        color: {
            type: String,
            default: '#009dff'
        },
        dividerColor: {
            type: String,
            default: '#FFFFFF'
        },
        dividerWidth: {
            type: Number,
            default: 2
        }
    },
    data()
    {
      return {
          opacityAdjustment: 0
      }
    },
    computed: {
        countTotal()
        {
            let count = 0;
            for(const item of this.items)
            {
                count += item.count;
            }
            return count;
        },
        itemData()
        {
            const items = this.items;
            let rotation = 0;
            let currentHighestPct = 0;

            items.map(
                (item) =>
                {
                    const pct = item.count / this.countTotal;
                    if(pct > currentHighestPct)
                    {
                        currentHighestPct = pct;
                    }
                    item['countTotal'] = this.countTotal;
                    item['strokeDashArray'] = pct * this.radius * this.PI + ' ' + this.radius * this.PI;
                    item['pct'] = pct;
                    item['rotation'] = rotation;
                    item['pctReadable'] = Math.round(pct * 10000) / 100 + '%';
                    rotation += 360 * pct;
                },
            );
            this.opacityAdjustment = 1 - currentHighestPct;
            return items;
        },
    },
    created()
    {
        this.PI = 3.14159265359;
        //this.opacityAdjustment = 0;
    },
    mounted()
    {
        //sconsole.log(this.itemData, this.opacityAdjustment);
    },
};
</script>
<style scoped lang="scss">
.chart-wrapper
{
    max-width: 500px;
    margin: 0 auto;

    .pie-chart
    {
        position: relative;
        padding-bottom: 100%;

        svg
        {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            *
            {
                transform-origin: center;
            }
        }
    }
}
.legend
{
    list-style: none;
    .dot
    {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: -26px;
        margin-right: 6px;
    }
}
</style>
