import moment from "moment";

function formatCurrency(value){
    let formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(value);
}
function formatDate(value){
    return moment(value).format("DD.MM.YYYY HH:mm");
}

export { formatCurrency, formatDate };
