<template>
	<svg v-if="name === 'cheveron-down'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
	</svg>
	<svg v-else-if="name === 'cheveron-right'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<polygon points="12.95 10.707 13.657 10 8 4.343 6.586 5.757 10.828 10 6.586 14.243 8 15.657 12.95 10.707"/>
	</svg>
	<svg v-else-if="name === 'dashboard'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm-5.6-4.29a9.95 9.95 0 0 1 11.2 0 8 8 0 1 0-11.2 0zm6.12-7.64l3.02-3.02 1.41 1.41-3.02 3.02a2 2 0 1 1-1.41-1.41z"/>
	</svg>
	<svg v-else-if="name === 'office'" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
		 viewBox="0 0 100 100">
		<path fill-rule="evenodd"
			  d="M7 0h86v100H57.108V88.418H42.892V100H7V0zm9 64h11v15H16V64zm57 0h11v15H73V64zm-19 0h11v15H54V64zm-19 0h11v15H35V64zM16 37h11v15H16V37zm57 0h11v15H73V37zm-19 0h11v15H54V37zm-19 0h11v15H35V37zM16 11h11v15H16V11zm57 0h11v15H73V11zm-19 0h11v15H54V11zm-19 0h11v15H35V11z"/>
	</svg>
	<svg v-else-if="name === 'printer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path d="M4 16H0V6h20v10h-4v4H4v-4zm2-4v6h8v-6H6zM4 0h12v5H4V0zM2 8v2h2V8H2zm4 0v2h2V8H6z"/>
	</svg>
	<svg v-else-if="name === 'trash'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"/>
	</svg>
	<svg v-else-if="name === 'faq'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
		<path
			d="m20.2,31.65c.53,0,.98-.18,1.35-.55.37-.37.55-.82.55-1.35s-.18-.98-.55-1.35c-.37-.37-.82-.55-1.35-.55s-.98.18-1.35.55c-.37.37-.55.82-.55,1.35s.18.98.55,1.35c.37.37.82.55,1.35.55Zm-1.75-7.3h2.95c0-.87.11-1.66.33-2.38.22-.72.89-1.54,2.02-2.48,1.03-.87,1.77-1.72,2.2-2.55s.65-1.75.65-2.75c0-1.77-.58-3.18-1.73-4.25s-2.68-1.6-4.58-1.6c-1.63,0-3.08.41-4.33,1.23s-2.16,1.94-2.72,3.38l2.65,1c.37-.93.92-1.66,1.65-2.18.73-.52,1.6-.77,2.6-.77,1.13,0,2.05.31,2.75.93.7.62,1.05,1.41,1.05,2.38,0,.73-.22,1.42-.65,2.08-.43.65-1.07,1.33-1.9,2.02-1,.87-1.74,1.72-2.23,2.58-.48.85-.73,1.98-.72,3.38Zm1.55,15.65c-2.73,0-5.32-.52-7.75-1.58-2.43-1.05-4.56-2.48-6.38-4.3s-3.25-3.94-4.3-6.38c-1.05-2.43-1.57-5.02-1.57-7.75s.53-5.37,1.57-7.8c1.05-2.43,2.48-4.55,4.3-6.35,1.82-1.8,3.94-3.22,6.38-4.28,2.43-1.05,5.02-1.58,7.75-1.58s5.37.53,7.8,1.57c2.43,1.05,4.55,2.48,6.35,4.28,1.8,1.8,3.23,3.92,4.28,6.35,1.05,2.43,1.58,5.03,1.58,7.8s-.52,5.32-1.58,7.75c-1.05,2.43-2.48,4.56-4.28,6.38-1.8,1.82-3.92,3.25-6.35,4.3-2.43,1.05-5.03,1.58-7.8,1.58Zm0-3c4.73,0,8.75-1.66,12.05-4.97,3.3-3.32,4.95-7.33,4.95-12.03s-1.65-8.75-4.95-12.05c-3.3-3.3-7.32-4.95-12.05-4.95s-8.71,1.65-12.02,4.95c-3.32,3.3-4.98,7.32-4.98,12.05s1.66,8.71,4.98,12.03c3.32,3.32,7.33,4.97,12.02,4.97Z"/>
	</svg>
	<svg v-else-if="name === 'users'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
		<path
			d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z"/>
	</svg>
	<svg v-else-if="name === 'insights'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z"/>
		<polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"/>
		<polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"/>
	</svg>
	<svg v-else-if="name === 'account'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M7.35,18.5C8.66,17.56,10.26,17,12,17 s3.34,0.56,4.65,1.5C15.34,19.44,13.74,20,12,20S8.66,19.44,7.35,18.5z M18.14,17.12L18.14,17.12C16.45,15.8,14.32,15,12,15 s-4.45,0.8-6.14,2.12l0,0C4.7,15.73,4,13.95,4,12c0-4.42,3.58-8,8-8s8,3.58,8,8C20,13.95,19.3,15.73,18.14,17.12z"/>
		<path
			d="M12,6c-1.93,0-3.5,1.57-3.5,3.5S10.07,13,12,13s3.5-1.57,3.5-3.5S13.93,6,12,6z M12,11c-0.83,0-1.5-0.67-1.5-1.5 S11.17,8,12,8s1.5,0.67,1.5,1.5S12.83,11,12,11z"/>
	</svg>
	<svg v-else-if="name === 'question'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
		<path
			d="M23.85 35.7q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm-1.65-7.4h2.85q0-1.55.5-2.525.5-.975 1.75-2.225 1.65-1.65 2.3-2.925.65-1.275.65-2.675 0-2.65-1.7-4.2-1.7-1.55-4.6-1.55-2.55 0-4.375 1.225Q17.75 14.65 16.9 16.8l2.65 1.1q.7-1.4 1.8-2.125 1.1-.725 2.6-.725 1.6 0 2.525.8.925.8.925 2.2 0 1-.55 1.925T25 22.15q-1.85 1.75-2.325 3-.475 1.25-.475 3.15ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z"/>
	</svg>
	<svg v-else-if="name === 'phoneCalls'" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960"
		 width="48">
		<path
			d="M776-487q-5-121-89-205t-205-89v-60q72 2 135.5 30.5T729-734q48 48 76.5 111.5T836-487h-60Zm-169 0q-5-50-40-84.5T482-611v-60q75 5 127.5 57T667-487h-60Zm188 367q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z"/>
	</svg>
	<svg v-else-if="name === 'leadsUnsent'" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960"
		 width="48">
		<path
			d="M480-463 140-680v460h386q0 14 2.5 30.5T535-160H140q-24 0-42-18.5T80-220v-520q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880-740v291q-11-6-28.5-11.5T820-469v-211L480-463Zm0-64 340-213H140l340 213ZM773-40q-77.605 0-132.302-54Q586-148 586-226.014q0-78.014 54.698-133Q695.395-414 773-414q77.605 0 132.302 54.986Q960-304.028 960-226.014 960-148 905.302-94 850.605-40 773-40ZM660-210h226v-40H660v40Zm-520-10v-520 520Z"/>
	</svg>
	<svg v-else-if="name === 'accepted'" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960"
		 width="48">
		<path
			d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/>
	</svg>
	<svg v-else-if="name === 'rejected'" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960"
		 width="48">
		<path
			d="m330-288 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/>
	</svg>
	<svg v-else-if="name === 'wizard'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
		 width="24px">
		<path
			d="M14.73,13.31C15.52,12.24,16,10.93,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.43,0,2.74-0.48,3.81-1.27L19.59,21L21,19.59L14.73,13.31z M9.5,14C7.01,14,5,11.99,5,9.5S7.01,5,9.5,5S14,7.01,14,9.5 S11.99,14,9.5,14z"/>
		<polygon
			points="10.29,8.44 9.5,6 8.71,8.44 6.25,8.44 8.26,10.03 7.49,12.5 9.5,10.97 11.51,12.5 10.74,10.03 12.75,8.44"/>
	</svg>
	<svg v-else-if="name === 'credit-card'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
		 width="24px">
		<path
			d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/>
	</svg>
	<svg v-else-if="name === 'super-admin'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
		 width="24px">
		<circle cx="17" cy="15.5" fill-rule="evenodd" r="1.12"/>
		<path
			d="M17,17.5c-0.73,0-2.19,0.36-2.24,1.08c0.5,0.71,1.32,1.17,2.24,1.17 s1.74-0.46,2.24-1.17C19.19,17.86,17.73,17.5,17,17.5z"
			fill-rule="evenodd"/>
		<path
			d="M18,11.09V6.27L10.5,3L3,6.27v4.91c0,4.54,3.2,8.79,7.5,9.82 c0.55-0.13,1.08-0.32,1.6-0.55C13.18,21.99,14.97,23,17,23c3.31,0,6-2.69,6-6C23,14.03,20.84,11.57,18,11.09z M11,17 c0,0.56,0.08,1.11,0.23,1.62c-0.24,0.11-0.48,0.22-0.73,0.3c-3.17-1-5.5-4.24-5.5-7.74v-3.6l5.5-2.4l5.5,2.4v3.51 C13.16,11.57,11,14.03,11,17z M17,21c-2.21,0-4-1.79-4-4c0-2.21,1.79-4,4-4s4,1.79,4,4C21,19.21,19.21,21,17,21z"
			fill-rule="evenodd"/>
	</svg>
	<svg v-else-if="name === 'edit'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
		<path
			d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
	</svg>
	<svg v-else-if="name === 'search'" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 -960 960 960">
		<path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/>
	</svg>
	<svg v-else-if="name === 'save'" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 -960 960 960">
		<path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/>
	</svg>
	<svg v-else-if="name === 'cancel'" xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 -960 960 960">
		<path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
	</svg>
</template>

<script>
export default {
	props: {
		name: String,
	},
};
</script>
