<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import CreateButton from '@/Shared/CreateButton';
import Icon from "@/Shared/Icon.vue";

export default {
    components: {
		Icon,
        CreateButton,
        BackendLayout,
        Link,
        Head,
    },
    layout: Layout,
    props: {
        users: Object,
        limit: Number,
        //filters: Object,
        //offerers: Array,
        //landingpages: Object,
        test: Object,
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                //dateStart: this.filters?.dateStart,
                //dateEnd: this.filters?.dateEnd,
                // offerers: this.filters?.offerers,
                //landingpages: this.filters?.landingpages,
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
            selectedUser: null
        };
    },
    mounted()
    {
      //console.log(this)
    },
    watch: {
        options: {
            deep: true,
            handler: () => null,
            /*
             handler: throttle(
             function(oldVal, newVal)
             {
             this.$inertia.get(route('insights.users'), pickBy(this.options), {preserveState: true});
             },
             150,
             ),

             */
        },
    },
    methods: {
        dump()
        {
            //console.log(this);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        setDates(dates)
        {
            if(dates)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = null;
                this.options.dateEnd = null;
            }
        },
        userHasRole(user, role)
        {
            return user.roles.includes(role);
        },
        destroy(id) {
            this.$inertia.delete(route('insights.users.destroy',{ user: id }))
        },
    },
};
</script>

<template>
    <Head title="Benutzer"/>
    <div class="row users-list">
        <div class="col-sm-6 col-md-4 col-lg-3 mb-3" v-for="user in users.data">
            <div class="card mb-3">
                <div class="achievements">
                    <div class="icon super-admin" v-if="userHasRole(user, 'Super-Admin')">
						<figure class="mb-0" title="Super-Admin">
							<icon name="super-admin" width="24" height="24"/>
						</figure>
                    </div>
                    <div class="icon payment-provider" v-if="userHasRole(user, 'Payment provider')">
						<figure class="mb-0" title="Payment provider">
							<icon name="credit-card" width="24" height="24"/>
						</figure>
                    </div>
                    <div class="icon dashboard-only" v-if="userHasRole(user, 'Dashboard only')">
						<figure class="mb-0" title="Dashboard only">
							<icon name="dashboard" width="24" height="24"/>
						</figure>
                    </div>
					<div class="icon offer-wizard" v-if="userHasRole(user, 'Offer wizard')">
						<figure class="mb-0" title="Offer wizard">
							<icon name="wizard" width="24" height="24"/>
						</figure>
					</div>

                </div>
                <div class="card-header">
                    <h5 class="card-title mb-0">{{user.name}}</h5>
                    <p class="small mb-0">{{user.email}}</p>
                    <button class="btn text-danger action" type="button" data-bs-toggle="modal" data-bs-target="#delete-user-confirmation" @click="selectedUser=user" title="Delete user">
						<icon name="trash" width="24" height="24"/>
					</button>
                </div>
                <div class="card-body">
                    <ul>
                        <li>Roles:
                            <ul class="roles-list">
                                <li v-for="role in user.roles">
									<div class="d-flex align-items-center">
										<div class="me-2">
											<div class="icon super-admin" v-if="role === 'Super-Admin'">
												<figure class="mb-0" title="Super-Admin">
													<icon name="super-admin" width="24" height="24" class="d-block"/>
												</figure>
											</div>
											<div class="icon payment-provider" v-if="role === 'Payment provider'">
												<figure class="mb-0" title="Payment provider">
													<icon name="credit-card" width="24" height="24" class="d-block"/>
												</figure>
											</div>
											<div class="icon dashboard-only" v-if="role === 'Dashboard only'">
												<figure class="mb-0" title="Dashboard only">
													<icon name="dashboard" width="24" height="24" class="d-block"/>
												</figure>
											</div>
											<div class="icon offer-wizard" v-if="role === 'Offer wizard'">
												<figure class="mb-0" title="Offer wizard">
													<icon name="wizard" width="24" height="24" class="d-block"/>
												</figure>
											</div>
										</div>
										<div>{{role}}</div>
									</div>

								</li>
                            </ul>
                        </li>
                        <li v-if="user.offerers.length > 0">Associated providers:
                            <ul>
                                <li v-for="offerer in user.offerers">{{offerer.name}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="d-flex card-footer justify-content-end">
                    <Link class="btn action" :href="route('insights.users.edit',user.id)" title="Edit user">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                    </Link>

                </div>
            </div>
        </div>
    </div>
    <nav v-if="users.links.length > 3">
        <ul class="pagination">
            <template v-for="link in users.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
    <CreateButton :url="route('insights.users.create')"></CreateButton>
    <div class="modal" tabindex="-1" id="delete-user-confirmation">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Do you really want to delete {{selectedUser?.name}}?</p>
                    <p>Email: {{selectedUser?.email}}</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn action primary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn action danger" data-bs-dismiss="modal" @click="destroy(selectedUser?.id)">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";

.card
{
    height: 100%;
    .achievements
    {
        display: flex;
        padding: 0 5px;


    }
	.icon
	{
		flex: 0 0 auto;
		width: auto;
		padding: 5px;

		&.super-admin
		{
			color: $type-primary;
		}
		&.payment-provider
		{
			color: var(--pvd-primary);
		}
		&.dashboard-only
		{
			color: var(--pvd-secondary);
		}


		svg
		{
			display: block;
			fill: currentColor;
		}
	}
	.roles-list
	{
		list-style: none;
		padding: 0;
	}
}
.card-header
{
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    padding: 15px 54px 15px 15px;
    position: relative;
    min-height: 56px;

    .btn
    {
        &.action
        {
            box-shadow: none;
            background: none;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

</style>
