<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        reporting: Object,
        reportingEntries: Object,
        limit: Number,
        filters: Object,
        offerer: Object,
        offerers: Object,
		total: Number
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                offerers: this.filters?.offerers,
                campaignId: this.filters?.campaignId,
                leadStates: this.filters?.leadStates
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
                    this.$inertia.get(route('insights.reportingEntries'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        }
    },
};
</script>

<template>
    <Head title="Reporting Einträge"/>
	
	<div class="row mb-3">
		<div class="col-12 mx-2">
			<br/>
			<h1 class="mb-2">Reporting #{{ reporting.id }}</h1>
			<h3>Anbieter:&nbsp;{{ offerer.name }}</h3>
		</div>
	</div>

	<div class="row mt-2 mb-6 alert alert-dark gap-4" v-if="reporting.type == 'export'">
		<div class="col-auto mx-2">
			<strong>Type:</strong><br/>Export
		</div>
		<div class="col-auto mx-2">
			<strong>Erstellt am</strong><br/>{{ formatDate(reporting.created_at) }}
		</div>
		<div class="col-auto mx-2">
			<strong>Datei:</strong><br/>{{ reporting.filename }}
		</div>
		<div class="col-auto mx-2">
			<strong>CSV-Einträge:</strong><br/>{{ reporting.total }}
		</div>
	</div>
	
	<div class="row mt-2 mb-6 alert alert-dark gap-4" v-if="reporting.type == 'import'">
		<div class="col-auto mx-2">
			<strong>Type:</strong><br/>Import
		</div>
		<div class="col-auto mx-2">
			<strong>Import am</strong><br/>{{ formatDate(reporting.created_at) }}
		</div>
		<div class="col-auto mx-2">
			<strong>Datei:</strong><br/>{{ reporting.filename }}
		</div>
		<div class="col-auto mx-2">
			<strong>Quelle:</strong><br/>{{ (reporting.context == 'upload') ? 'Manueller Upload in Insights' : 'Automatischer Import per SFTP' }}
		</div>
	</div>
	
	<div class="row mt-2 mb-3" v-if="reporting.type == 'import'">		
		<div class="col-6 col-md-2 mr-2 alert alert-dark">
			<strong>Gesamt:</strong><br/>{{ reporting.total }}
		</div>
		
		<div class="col-6 col-md-2 mx-2 alert alert-success">
			<strong>Erfolgreich:</strong><br/>{{ reporting.success }}
		</div>
		
		<div class="col-6 col-md-2 mx-2 alert alert-danger">
			<strong>Fehler:</strong><br/>{{ reporting.failure }}
		</div>
		
		<div class="col-6 col-md-2 mx-2 alert alert-warning">
			<strong>Warnungen:</strong><br/>{{ reporting.warnings }}
		</div>
		
		<div class="col-6 col-md-2 mx-2 alert alert-primary">
			<strong>Unbekannt:</strong><br/>{{ reporting.unknown }}
		</div>
	</div>
	
    <div class="row my-3 align-items-center" v-if="reporting.filename">
		<div class="col-12">
			<div class="row justify-content-center">
				<div class="col-auto">
					<br/>
					<a :href="route('insights.reportings.download', this.reporting.id)" class="btn fs-6" target="_blank">
						<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="48"><path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"/></svg>
						CSV-Datei herunterladen
					</a>
					<br/>
				</div>
			</div>
        </div>
    </div>

    <div class="table-responsive" v-if="reportingEntries.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Zeile</th>
					<th>PAYVED-ID</th>
					<th>Lead-Datum</th>
					<th>Import-Typ</th>
					<th>Import-Meldungen</th>
					<th>CSV Lead-Status</th>
					<th>CSV Lead-Substatus</th>
					<th>CSV Datum</th>
					<th>CSV Anmerkungen</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="reportingEntry in reportingEntries.data">
					<td><span :title="'ID: '+reportingEntry.id">#{{ reportingEntry.row }}</span></td>
					<td>{{reportingEntry.payved_id}}</td>
					<td><span :title="reportingEntry.lead.name+' ('+reportingEntry.lead.company+')'">{{ (formatDate(reportingEntry.lead_modified_at) == 'Invalid date') ? '' : formatDate(reportingEntry.lead.created_at) }}</span></td>
					<td>
						<span v-if="reportingEntry.result === 'Fehler'" class="badge bg-danger text-white p-1">{{reportingEntry.result}}</span>
						<span v-if="reportingEntry.result === 'Warnung'" class="badge bg-warning text-black p-1">{{reportingEntry.result}}</span>
						<span v-if="reportingEntry.result === 'Erfolgreich'" class="badge bg-success text-white p-1">{{reportingEntry.result}}</span>
					</td>
					<td>
						<ul>
							<li v-for="log in reportingEntry.logs">{{ log.msg }}</li>
						</ul>
					</td>
					<td>{{reportingEntry.lead_status}}</td>
					<td>{{reportingEntry.lead_substatus}}</td>
					<td>{{ (formatDate(reportingEntry.lead_modified_at) == 'Invalid date') ? '' : formatDate(reportingEntry.lead_modified_at) }}</td>
					<td>{{reportingEntry.lead_notes}}</td>
				</tr>
            </tbody>
        </table>
    </div>
	
	<br>
	<a :href="route('insights.reportings')">Zurück zur Übersicht</a><br>
	<br>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
</style>
