<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";

export default {
    components: {
        BackendLayout,
        Link,
        Head,
    },
    layout: Layout,
    props: {
        leads: Object,
		total: Number
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
                    this.$inertia.get(route('insights.leads'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
    },
};
</script>

<template>
    <Head title="Marketing Qualified Leads"/>
	<h1>Nicht verschickte MQLs</h1>
	
    <div class="filters">
        <div class="row">
			<div class="col-12" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
				<div class="campaign-head">
					<label for="campaignSelection">Kampagne:</label>
					<select v-model="options.campaignId" class="form-select mt-1 w-full" id="campaignSelection">
						<option value="1">PAYVED Default</option>
						<option value="3">PAYVED Acceptance Initiative</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-6">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl der MQLs:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="leads.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Erstellt am</th>
					<th>Vorname</th>
					<th>Nachname</th>
					<th>Firma</th>
					<th>Telefon</th>
					<th>E-Mail</th>
					<th>Kontaktart</th>
					<th>Anbieter</th>
					<th>Produkt</th>
					<th>Quelle</th>
					<th>Branche</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="lead in leads.data">
					<td><span :title="'ID: '+lead.id">{{ formatDate(lead.created_at) }}</span></td>
					<td>{{lead.firstname}}</td>
					<td>{{lead.lastname}}</td>
					<td>{{lead.company}}</td>
					<td>{{lead.phone}}</td>
					<td>{{lead.email}}</td>
					<td>{{lead.processingName}}</td>
					<td>{{lead.offerer_name}}</td>
					<td>{{lead.offer_name}}</td>
					<td>{{lead.source_name}}</td>
					<td>{{lead.industry_name}}</td>
				</tr>
            </tbody>
        </table>
    </div>

    <h3 v-else>{{noDataAvailable}}</h3>
    <nav v-if="leads.links.length > 3">
        <ul class="pagination flex-wrap">
            <template v-for="link in leads.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active, 'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";



</style>
