<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        //leads: Object,
        //limit: Number,
        filters: Object,
        offerers: Object,
        industries: Object,
        sources: Object,
        platforms: Object,
        processing: Object,
		specialCriterias: Object,
        stats: Object,
    },
    data()
    {
        return {
            options: {
                //limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                offerers: this.filters?.offerers,
                industries: this.filters?.industries,
                sources: this.filters?.sources,
                platforms: this.filters?.platforms,
                processing: this.filters?.processing,
                campaignId: this.filters?.campaignId,
                specialCriterias: this.filters?.specialCriterias,
            },
        };
    },
    computed:{
        statsSorted()
        {
            const offerers = Object.values(this.stats.countByPP).sort((a,b) => a['count'] - b['count']);
            const industries = Object.values(this.stats.countByLP).sort((a,b) => a['count'] - b['count']);
            const sources = Object.values(this.stats.countBySources).sort((a,b) => a['count'] - b['count']);
            const processing = Object.values(this.stats.countBySources).sort((a,b) => a['count'] - b['count']);
            const dates = Object.values(this.stats.countByDate).sort((a,b) => a['id'].localeCompare(b['id'], 'en'));
            const leadsByCalendarWeek = Object.values(this.stats.countByCalendarWeek);
            return {offerers: offerers, industries: industries, sources: sources, processing: processing, dates: dates, leadsByCalendarWeek: leadsByCalendarWeek};
        }
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.offerers.length == 0) this.options.offerers = [-1];
					if(this.options.industries.length == 0) this.options.industries = [-1];
					if(this.options.sources.length == 0) this.options.sources = [-1];
					if(this.options.platforms.length == 0) this.options.platforms = [-1];
					if(this.options.processing.length == 0) this.options.processing = [-1];
					if(this.options.campaignId.length == 0) this.options.campaignId = 1;
					if(this.options.specialCriterias.length == 0) this.options.specialCriterias = [-1];
                    this.$inertia.get(route('insights.dashboard'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    methods: {
        dump()
        {
            //console.log(this);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
        userCan(permission)
        {
            if(this.$parent.auth.user.roles.includes('Super-Admin'))
            {
                return true;
            }

            return this.$parent.auth.user.permissions.filter((p) => p.name === permission).length > 0;
        }
    },
    mounted()
    {
        //console.log(this)
    }
};
</script>

<template>
    <Head title="Dashboard"/>
    <div class="filters">
        <div class="row">
			<div class="col-12" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
				<div class="campaign-head">
					<label for="campaignSelection">Kampagne:</label>
					<select v-model="options.campaignId" class="form-select mt-1 w-full" id="campaignSelection">
						<option value="1">PAYVED Default</option>
						<option value="3">PAYVED Acceptance Initiative</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3" v-if="offerers.length > 1">
        <div class="col-12">
            <span>Filter nach Zahlungsanbieter:</span>
        </div>
        <div class="col-auto" v-for="offerer in offerers">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'offerer-' + offerer.id" name="offerers" :value="offerer.id" v-model="options.offerers"/>
                <label class="form-check-label" :for="'offerer-' + offerer.id">{{offerer.name}}</label>
            </div>
        </div>

    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Quelle:</span>
        </div>
        <div class="col-auto" v-for="source in sources">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'source-' + source.id" name="sources" :value="source.id" v-model="options.sources"/>
                <label class="form-check-label" :for="'source-' + source.id">{{source.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Werbeplattform:</span>
        </div>
        <div class="col-auto" v-for="platform in platforms">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'platform-' + platform.id" name="platforms" :value="platform.id" v-model="options.platforms"/>
                <label class="form-check-label" :for="'platform-' + platform.id">{{platform.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Kontaktart:</span>
        </div>
        <div class="col-auto" v-for="proc in processing">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'proc-' + proc.id" name="processing" :value="proc.id" v-model="options.processing"/>
                <label class="form-check-label" :for="'proc-' + proc.id">{{proc.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Branche:</span>
        </div>
        <div class="col-auto" v-for="industry in industries">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'industry-' + industry.id" name="industries" :value="industry.id" v-model="options.industries"/>
                <label class="form-check-label" :for="'industry-' + industry.id">{{industry.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Sonstige Filter:</span>
        </div>
		<div class="col-auto" v-for="specialCriteria in specialCriterias">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'specialCriteria-' + specialCriteria.id" name="specialCriterias" :value="specialCriteria.id" v-model="options.specialCriterias"/>
                <label class="form-check-label" :for="'specialCriteria-' + specialCriteria.id">{{specialCriteria.name}}</label>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" v-if="stats.countAll > 0">
        <div class="col-12 mb-5">
            <h4>Anzahl der MQLs: {{stats.countAll}}</h4>
        </div>
        <div class="col-lg-4 mb-3" v-if="offerers.length > 1">
            <pie-chart :items="statsSorted.offerers"></pie-chart>
        </div>
        <div class="col-lg-4 mb-3">
            <pie-chart :items="statsSorted.industries"></pie-chart>
        </div>
        <div class="col-lg-4 mb-3">
            <pie-chart :items="statsSorted.sources"></pie-chart>
        </div>
        <div class="col-12">
            <bar-chart :items="statsSorted.leadsByCalendarWeek"></bar-chart>
        </div>
    </div>
    <h3 v-else>Keine Einträge vorhanden</h3>

</template>

