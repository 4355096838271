<template>
    <div>
        <div v-if="$page.props.flash.success && show">
            <div class="alert alert-dismissible alert-success fade show d-flex align-items-center" role="alert">
                <svg class="icon flex-shrink-0 me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/></svg>
                <div>{{$page.props.flash.success}}</div>
                <button type="button" class="btn-close" aria-label="Close" @click="show = false"></button>
            </div>
        </div>
        <div v-if="($page.props.flash.error || Object.keys($page.props.errors).length > 0) && show">
            <div class="alert alert-dismissible alert-danger fade show d-flex align-items-center" role="alert">
                <svg class="icon flex-shrink-0 me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>
                <div v-if="$page.props.flash.error">{{$page.props.flash.error}}</div>
                <div v-else>
                    <span v-if="Object.keys($page.props.errors).length === 1">There is one form error.</span>
                    <span v-else>There are {{Object.keys($page.props.errors).length}} form errors.</span>
                </div>
                <button type="button" class="btn-close" aria-label="Close" @click="show = false"></button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data()
    {
        return {
            show: true,
        };
    },
    watch: {
        '$page.props.flash': {
            handler()
            {
                this.show = true;
            },
            deep: true,
        },
    },
};
</script>
