<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import Icon from '@/Shared/Icon'
import {formatDate} from "@/custom";

export default {
    components: {
        BackendLayout,
        Link,
        Head,
		Icon
    },
    layout: Layout,
    props: {
        leads: Object,
        limit: Number,
        filters: Object,
        paymentproviders: Array,
        landingpages: Object,
        test: Object,
		total: Number
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                paymentproviders: this.filters?.paymentproviders,
                landingpages: this.filters?.landingpages,
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
                    this.$inertia.get(route('insights.faq'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.paymentproviders.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = null;
                this.options.dateEnd = null;
            }
        },
    },
};
</script>

<template>
    <Head title="F.A.Q."/>
	<h1>F.A.Q.</h1>
	
	<h3 class="mt-5 mb-3">Dashboard</h3>
	<dl>
		<dt>
			<span class="icon"><icon name="faq" width="35" height="35" viewBox="0 0 48 48" /></span>
			<span class="label">Warum sehe ich mehrere angehakte Felder mit demselben Namen?</span>
		</dt>
		<dd>Das passiert, wenn wir ein A/B-Testing mit verschiedenen Landing Pages für einen Anbieter durchführen. Diese tauchen in der Auswertung dann einzeln auf. Für die Gesamtzahl an Leads einfach alle anhaken.</dd>
	</dl>
	
	<h3 class="mt-5 mb-3">MQLs</h3>
	
	<dl>
		<dt>
			<span class="icon"><icon name="faq" width="35" height="35" viewBox="0 0 48 48" /></span>
			<span class="label">Warum sind die MQL-Zahlen für “Einzelhandel” und die anderen Branchen so niedrig?</span>
		</dt>
		<dd>Weil unsere Kampagne derzeit am besten über den Weg “Alle Angebote” performed. Es kann aber sein, dass wir das in der Zukunft nochmal ändern, darum bleibt die Separierung bestehen.</dd>
		<dt>
			<span class="icon"><icon name="faq" width="35" height="35" viewBox="0 0 48 48" /></span>
			<span class="label">Was darf ich mit den heruntergeladenen Daten tun?</span>
		</dt>
		<dd>Hier gelten dieselben Datenschutzregeln wie für die Einzelzusendungen per E-Mail aus dem Formular auf der PAYVED Website. Der Download ist lediglich ein Feature, um den Anbieter eine Möglichkeit zu bieten, alle qualified Leads in einem bestimmten Zeitraum sehen zu können. Zum Beispiel um zu kontrollieren, ob sich die Daten mit der internen Anzahl decken.</dd>
	</dl>

	
	<h3 class="mt-5 mb-3">Benutzer</h3>
	<dl>
		<dt>
			<span class="icon"><icon name="faq" width="35" height="35" viewBox="0 0 48 48" /></span>
			<span class="label">Was mache ich, wenn ich mein Passwort vergessen habe?</span>
		</dt>
		<dd>Dann bitte bei insights@payved.de oder einfach einem anderen Ansprechpartner bei BIRCH COVE melden. Diese Information bitte merken, denn wenn man sein Passwort vergessen hat, kommt man ja gar nicht bis zu diesem FAQ-Eintrag ;-)</dd>
	</dl>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";

</style>
