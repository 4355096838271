<template>
    <div class="create-button-spacer"></div>
    <div class="create-button">
        <div class="container-fluid d-flex justify-content-end">
            <Link class="btn action" :href="url">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
            </Link>
        </div>
    </div>

</template>

<script>
import { Link } from '@inertiajs/inertia-vue3';
export default {
    components: {
      Link
    },
    props: {
        url: String,
    },
};
</script>
<style scoped lang="scss">
@import "resources/sass/partials/variables";
.create-button-spacer
{
    display: block;
    height: 100px;
}
.create-button
{
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;

    .btn
    {
        &.action
        {
            border-radius: 50%;
            width: 55px;
            height: 55px;

            svg
            {
                fill: $type-primary;
            }
        }
    }
}
</style>
