<template>
	<div class="form-floating" :class="$attrs.class">
	  <textarea :id="id"
				ref="textarea"
				v-bind="{ ...$attrs, class: null }"
				class="form-textarea form-control"
				:class="{ error: error }"
				placeholder=" "
				:required="required ? 'required' : null"
				v-model="value"
				@input="$emit('update:modelValue', $event.target.value)"></textarea>
		<label v-if="label" class="form-label" :for="id">{{ error || label }}</label>
	</div>
</template>

<script>
import {v4 as uuid} from 'uuid'

export default {
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			default() {
				return `textarea-input-${uuid()}`
			},
		},
		required: {
			type: Boolean,
			default: false
		},
		error: String,
		label: String,
		modelValue: String,
	},
	data: function()
	{
		return {
			value: this.modelValue
		}
	},
	emits: ['update:modelValue'],
	methods: {
		focus() {
			this.$refs.input.focus()
		},
		select() {
			this.$refs.input.select()
		},

	},
}
</script>
<style scoped lang="scss">
label {
	font-size: 16px;
}

textarea {
	width: 100%;
	min-height: 120px;

	&.has-toggle {
		padding-right: 60px;
	}

	&.is-invalid {
		+ label {
			color: #F03869;
		}
	}
}

</style>
