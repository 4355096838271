<template>
    <div>
        <Head title="Create User"/>
		<div class="row justify-content-between">
			<div class="col">
				<h1>
					<Link :href="route('insights.users')">Users</Link>
					<span> / </span>Create
				</h1>
			</div>
		</div>

        <div class="rounded shadow p-3">
            <form @submit.prevent="store">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <p>Main data</p>
                        </div>
						<div class="col-auto">
							<Link :href="route('insights.users')" class="btn-close d-inline-block"></Link>
						</div>
					</div>
					<div class="row">
                        <div class="col-12">
                            <text-input v-model="form.name" :error="form.errors.name" class="mb-3" label="Name"/>
                            <text-input v-model="form.email" :error="form.errors.email" class="mb-3" label="Email"/>
                            <text-input v-model="form.password" :error="form.errors.password" class="mb-3" type="password" autocomplete="new-password" label="Password"/>
                            <text-input v-model="form.passwordRepeat" :error="form.errors.passwordRepeat" class="mb-3" type="password" label="Repeat password"/>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-12">
                            <p>Anbieter</p>
                        </div>
						<div class="col-12">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="select-all-offerers" name="select-all-offerers" v-model="selectAllOfferers"/>
								<label class="form-check-label" for="select-all-offerers">Alle aus-/abwählen</label>
							</div>
						</div>

                        <div class="col-auto" v-for="offerer in offerers">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :id="'cb-pp-' + offerer.identifier" name="offerers" :value="offerer.id" v-model="form.offerers"/>
                                <label class="form-check-label" :for="'cb-pp-' + offerer.identifier">{{offerer.name}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p>Roles</p>
                        </div>
                        <div class="col-auto" v-for="role in roles">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :id="'role-' + role.id" name="offerers" :value="role.name" v-model="form.roles"/>
                                <label class="form-check-label" :for="'role-' + role.id">{{role.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <loading-button :loading="form.processing" class="btn primary action" type="submit">Create User</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3';
import Layout from '@/Shared/Layout';
import FileInput from '@/Shared/FileInput';
import TextInput from '@/Shared/TextInput';
import SelectInput from '@/Shared/SelectInput';
import LoadingButton from '@/Shared/LoadingButton';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import BackendLayout from '@/Layouts/BackendLayout.vue';

export default {
    components: {
        FileInput,
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        JetCheckbox,
    },
    layout: Layout,
    //remember: 'form',
    props: {
        offerers: Array,
        roles: Array
    },
    data()
    {
        return {
            form: this.$inertia.form(
                {
                    name: '',
                    email: '',
                    password: '',
                    passwordRepeat: '',
                    offerers: [],
                    roles: [],
                },
            ),
        };
    },
    mounted()
    {
        //console.log(this);
    },
    methods: {
        store()
        {
            this.form.post(route('insights.users.store'));
        },
    },
	computed: {
		selectAllOfferers: {
			get: function () {
				return this.form.offerers.length === this.offerers.length;
			},
			set: function (value) {
				const selected = [];
				if (value) {

					this.offerers.forEach(function (offerer) {
						selected.push(offerer.id);
					});
				}
				this.form.offerers = selected;
			}
		}
	}
};
</script>
