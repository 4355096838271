<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import Icon from '@/Shared/Icon';
import axios from 'axios';
			
export default {
    components: {
        BackendLayout,
        Link,
        Head,
		Icon,
    },
    layout: Layout,
    props: {
        calls: Object,
        limit: Number,
        filters: Object,
        phoneExtensions: Object,
		total: Number
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                phoneExtensions: this.filters?.phoneExtensions,
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.phoneExtensions.length == 0) this.options.phoneExtensions = [-1];
                    this.$inertia.get(route('insights.phoneCalls'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.phoneExtensions.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
		acceptAsLead(phoneCallsId)
		{
			console.log('Accept...');
			this.$inertia.put(route('insights.phoneCalls.acceptAsLead', { phoneCalls: phoneCallsId }));
		},
		rejectAsLead(phoneCallsId)
		{
			console.log('Reject...');
			this.$inertia.put(route('insights.phoneCalls.rejectAsLead', { phoneCalls: phoneCallsId }));
		}
    },
};
</script>

<template>
    <Head title="Telefon-Anrufe"/>
	<h1>Telefon-Anrufe</h1>
    <div class="filters">
        <div class="row">
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3" v-if="phoneExtensions.length > 1">
        <div class="col-12">
            <span>Filter nach Durchwahl:</span>
        </div>
        <div class="col-auto" v-for="phoneExtension in phoneExtensions">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="phoneExtension.extension" name="phoneExtensions" :value="phoneExtension.extension" v-model="options.phoneExtensions"/>
                <label class="form-check-label" :for="phoneExtension.extension">{{phoneExtension.name}}</label>
            </div>
        </div>

    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-4">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="calls.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Erstellt am</th>
					<th>Durchwahl</th>
					<th>Durchwahl-Name</th>
					<th>Anrufer</th>
					<th>Dauer</th>
					<th>Ergebnis</th>
					<th>&nbsp;</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="call in calls.data">
					<td><span :title="'ID: '+call.id">{{ formatDate(call.created_at) }}</span></td>
					<td>{{call.dst_extension}}</td>
					<td>{{call.dst_name}}</td>
					<td>{{call.src_number}}</td>
					<td>{{call.durationFormatted}}</td>
					<td>{{call.result}} {{call.result_details}}</td>
					<td>
						<button class="fs-6" @click="acceptAsLead(call.id)">
							<icon name="accepted" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
						</button>
						<button class="fs-6" @click="rejectAsLead(call.id)">
							<icon name="rejected" class="d-lg-block mx-lg-auto p-2" width="50" height="50"/>
						</button>
					</td>
				</tr>
            </tbody>
        </table>
    </div>

    <h3 v-else>{{noDataAvailable}}</h3>
    <nav v-if="calls.links.length > 3">
        <ul class="pagination flex-wrap">
            <template v-for="link in calls.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active, 'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";



</style>
