<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        reportings: Object,
        limit: Number,
        filters: Object,
        reportingTypes: Object,
        contentTypes: Object,
		specialCriterias: Object,
        offerers: Object,
		total: Number,
        stats: Object
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                reportingTypes: this.filters?.reportingTypes,
                contentTypes: this.filters?.contentTypes,
                specialCriterias: this.filters?.specialCriterias,
                offerers: this.filters?.offerers,
                campaignId: this.filters?.campaignId,
                leadStates: this.filters?.leadStates
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.offerers.length == 0) this.options.offerers = [-1];
					if(this.options.reportingTypes.length == 0) this.options.reportingTypes = [-1];
					if(this.options.contentTypes.length == 0) this.options.contentTypes = [-1];
					if(this.options.specialCriterias.length == 0) this.options.specialCriterias = [-1];
                    this.$inertia.get(route('insights.reportings'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return this.filters.offerers.includes(-1) ? 'You are not allowed to view this data' : 'Nothing found';
        },
        statsSorted()
        {
            const leadStatus = Object.values(this.stats.leadStatus);
            const leadStatusProcessing = Object.values(this.stats.leadStatusProcessing);
            const leadStatusFailure = Object.values(this.stats.leadStatusFailure);
            const leadStatusLost = Object.values(this.stats.leadStatusLost);
            return {leadStatus: leadStatus, leadStatusProcessing: leadStatusProcessing, leadStatusFailure: leadStatusFailure, leadStatusLost: leadStatusLost};
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        },
        exportLeads()
        {
            //window.location.href = route('insights.lead.export');
        },
    },
};
</script>

<template>
    <Head title="Reportings"/>
	<h1>Reportings</h1>
	
    <div class="filters">
        <div class="row">
			<div class="col-12" v-if="this.$parent.auth.user.roles.includes('Super-Admin')">
				<div class="campaign-head">
					<label for="campaignSelection">Kampagne:</label>
					<select v-model="options.campaignId" class="form-select mt-1 w-full" id="campaignSelection">
						<option value="1">PAYVED Default</option>
						<option value="3">PAYVED Acceptance Initiative</option>
					</select>
				</div>
			</div>
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3" v-if="offerers.length > 1">
        <div class="col-12">
            <span>Filter nach Zahlungsanbieter:</span>
        </div>
        <div class="col-auto" v-for="offerer in offerers">
            <div class="form-check">
				<input class="form-check-input" type="checkbox" :id="'offerer-' + offerer.id" name="offerers" :value="offerer.id" v-model="options.offerers"/>
                <label class="form-check-label" :for="'offerer-' + offerer.id">{{offerer.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3" v-if="reportingTypes.length > 1">
        <div class="col-12">
            <span>Filter nach Reporting-Type:</span>
        </div>
        <div class="col-auto" v-for="reportingType in reportingTypes">
            <div class="form-check">
				<input class="form-check-input" type="checkbox" :id="'reportingType-' + reportingType.id" name="reportingTypes" :value="reportingType.id" v-model="options.reportingTypes"/>
                <label class="form-check-label" :for="'reportingType-' + reportingType.id">{{reportingType.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3" v-if="contentTypes.length > 1">
        <div class="col-12">
            <span>Filter nach Content-Type:</span>
        </div>
        <div class="col-auto" v-for="contentType in contentTypes">
            <div class="form-check">
				<input class="form-check-input" type="checkbox" :id="'contentType-' + contentType.id" name="contentTypes" :value="contentType.id" v-model="options.contentTypes"/>
                <label class="form-check-label" :for="'contentType-' + contentType.id">{{contentType.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Sonstige Filter:</span>
        </div>
		<div class="col-auto" v-for="specialCriteria in specialCriterias">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'specialCriteria-' + specialCriteria.id" name="specialCriterias" :value="specialCriteria.id" v-model="options.specialCriterias"/>
                <label class="form-check-label" :for="'specialCriteria-' + specialCriteria.id">{{specialCriteria.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-4">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl der Reportings:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row justify-content-center">
				<div class="col-auto">
					&nbsp;
				</div>
			</div>
        </div>
		<div class="col-4">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="reportings.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Datum</th>
					<th>CSV-Datei</th>
					<th>Anbieter</th>
					<th>Type</th>
					<th>Gesamt</th>
					<th>Erfolgreich</th>
					<th>Fehlerhaft</th>
					<th>Warnungen</th>
					<th>Unbekannt</th>
					<th>&nbsp;</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="reporting in reportings.data">
					<td><span :title="'ID: '+reporting.id">{{ formatDate(reporting.created_at) }}</span></td>
					<td>{{reporting.filename}}</td>
					<td>{{reporting.offererName}}</td>
					<td>{{reporting.type}}</td>
					<td><span class="text-dark">{{reporting.total}}</span></td>
					<td><span class="text-sucess">{{reporting.success}}</span></td>
					<td><span class="text-danger">{{reporting.failure}}</span></td>
					<td><span class="text-warning">{{reporting.warnings}}</span></td>
					<td><span class="text-primary">{{reporting.unknown}}</span></td>
					<td>
						<Link class="btn action" :href="route('insights.reportings.show',reporting.id)" title="Details anzeigen">
							<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
						</Link>
					</td>
				</tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
</style>
