<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import InputError from '@/Shared/InputError.vue';
import { useForm } from '@inertiajs/inertia-vue3'

export default {
    components: {
        BackendLayout,
        Link,
        Head
    },
    layout: Layout,
    props: {
        offerers: Object,
		errors: Object,
    },
    data()
    {
		return {
			form: useForm({
				offerer: null,
				reportfile: null,
				notice: null
			})
		};
    },
    methods: {
		submit() {
			this.form.post(route('insights.reportings.import.store'), {
				onSuccess: () => {
				},
				onError: errors => {
				},
			});
		},
    },
};
</script>

<template>
    <Head title="Import eines CSV-Reportings"/>
	<h1>CSV-Reporting importieren</h1>
	<br/>
	<br/>
	
	<form @submit.prevent="submit">
		<div class="row mb-3" v-if="offerers.length > 0">
			<div class="col-6">
				<div class="col-12">
					<span>Anbieter:</span>
				</div>
				<div class="col-auto">
					<select id="offerer" class="form-select" v-model="form.offerer">
						<option :value="offerer.id" v-for="offerer in offerers">{{offerer.name}}</option>
					</select>
					<div v-if="form.errors.offerer" class="mb-3 alert-danger">{{ form.errors.offerer }}</div>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<span>CSV-Datei:</span>
			</div>
			<div class="col-auto">
				<input type="file" accept=".csv, text/csv" @input="form.reportfile = $event.target.files[0]" /><br/>
				<div v-if="form.errors.reportfile" class="mb-3 alert-danger">{{ form.errors.reportfile }}</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-12">
				<span>Anmerkungen (optional):</span>
			</div>
			<div class="col-6">
				<textarea v-model="form.notice" class="w-100"></textarea>
			</div>
		</div>
		<div>
			<progress v-if="form.progress" :value="form.progress.percentage" max="100">
				{{ form.progress.percentage }}%
			</progress>
		</div>
		<div class="row mb-3">
			<div class="col-auto">
				<button type="submit" class="btn primary action">Datei importieren</button>
			</div>
		</div>
		
		<br>
		<a :href="route('insights.reportings')">Zurück zur Übersicht</a>
	</form>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
</style>
