<script>
import {Head, Link, useForm, usePage} from '@inertiajs/inertia-vue3';
import LayoutPlain from '@/Shared/LayoutPlain';
import TextInput from "@/Shared/TextInput.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

export default {
	components: {
		LoadingButton,
		TextInput,
		Link,
		Head
	},
	layout: LayoutPlain,
	props: {
		displayMail: String,
	},
	data() {
		return {
			form: this.$inertia.form(
				{
					two_factor_code: '',
				},
			),
		};
	},
	methods: {
		validate() {
			this.form.post(route('code.2fa.validate'), {
				onSuccess: () => {
					this.form.reset();
					//this.form.reason = ''
				},
				onError: errors => {
				},

			});
		},
	},
}

</script>

<template>
	<h1>Zwei-Faktor-Authentifizierung</h1>
	<p>Bitte geben Sie den Auth-Code ein, den wir Ihnen per E-Mail an {{ displayMail }} geschickt haben.</p>
	
	<form class="row" @submit.prevent="validate">
		<div class="col-span-6 sm:col-span-4">
			<text-input v-model="form.two_factor_code" :error="form.errors.two_factor_code" class="mb-3" label="Auth-Code eingeben..."/>
			<loading-button :loading="form.processing" class="btn primary action" type="submit">Weiter</loading-button>
		</div>
	</form>
</template>
