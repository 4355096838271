<template>
	<div class="form-floating" :class="$attrs.class">
		<input :id="id"
			   ref="input"
			   v-bind="{ ...$attrs, class: null }"
			   class="form-control"
			   placeholder=" "
			   :class="{ 'show-search-button': showSearchButton }"
			   type="text"
			   :value="modelValue"
			   @input="$emit('update:modelValue', $event.target.value)"/>
		<label v-if="placeholder" :for="id">{{placeholder}}</label>
		<div class="inline-buttons">
			<button type="button" class="reset-button" v-if="modelValue?.length > 0" @click="$emit('update:modelValue', null)">
				<Icon name="cancel"></Icon>
			</button>
			<button type="button" class="search-button btn primary" v-if="showSearchButton">
				<Icon name="search"></Icon>
			</button>
		</div>
	</div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import Label from '@/Jetstream/Label';
import Input from '@/Jetstream/Input';
import Icon from "@/Shared/Icon.vue";

export default {
	components: {Input, Label, Icon},
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			default()
			{
				return `text-input-${uuid()}`;
			},
		},
		showSearchButton: {
			type: Boolean,
			default() {
				return true;
			}
		},
		placeholder: String,
		modelValue: String
	},
	mounted()
	{
		//console.log(this.error)
	},
	emits: ['update:modelValue', 'reset'],
	data: function()
	{
		return {
			//showPwd: false,
			//toggleType: this.type
		}
	},
	methods: {
		focus()
		{
			this.$refs.input.focus();
		},
		select()
		{
			this.$refs.input.select();
		},
		reset()
		{
			this.$refs.input.value = null;
		},
		setSelectionRange(start, end)
		{
			this.$refs.input.setSelectionRange(start, end);
		},
	},
};
</script>
<style scoped lang="scss">
label
{
	font-size: 16px;
}
input
{
	&:not(:placeholder-shown)
	{
		padding-right: 70px;
	}

	&.show-search-button
	{
		padding-right: 70px;
		&:not(:placeholder-shown)
		{
			padding-right: 130px;
		}
	}


	&.has-toggle
	{
		padding-right: 60px;
	}

	&.is-invalid
	{
		+label
		{
			color: #F03869;
		}
	}
}
.inline-buttons
{
	background: none;
	border: none;
	border-radius: .25rem;
	display: flex;
	outline: none;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	overflow: hidden;

	> button
	{
		display: block;
		//background: none;
		border: none;
		width: 60px;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		margin: 0;

		&.reset-button
		{
			background: none;
		}
	}


	svg
	{
		fill: currentColor;
		display: block;
		width: 30px;
		height: 30px;
		margin: auto;
	}
}
</style>
