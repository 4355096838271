<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import { Head, Link } from '@inertiajs/inertia-vue3';
import pickBy from 'lodash/pickBy';
import throttle from 'lodash/throttle';
import Layout from '@/Shared/Layout';
import {formatDate} from "@/custom";
import PieChart from '@/Shared/PieChart';
import BarChart from '@/Shared/BarChart';

export default {
    components: {
        BackendLayout,
        Link,
        Head,
        PieChart,
        BarChart,
    },
    layout: Layout,
    props: {
        calculations: Object,
        customerTypes: Object,
		specialCriterias: Object,
        limit: Number,
        filters: Object,
		total: Number,
        stats: Object
    },
    data()
    {
        return {
            options: {
                limit: this.limit,
                dateStart: this.filters?.dateStart,
                dateEnd: this.filters?.dateEnd,
                customerTypes: this.filters?.customerTypes,
                specialCriterias: this.filters?.specialCriterias,
            },
            limits: [
                {label: '10', limit: 10},
                {label: '25', limit: 25},
                {label: '100', limit: 100},
                {label: '250', limit: 250},
            ],
        };
    },
    watch: {
        options: {
            deep: true,
            handler: throttle(
                function(oldVal, newVal)
                {
					if(this.options.customerTypes.length == 0) this.options.customerTypes = [-1];
					if(this.options.specialCriterias.length == 0) this.options.specialCriterias = [-1];
                    this.$inertia.get(route('insights.finder'), pickBy(this.options), {preserveState: true});
                },
                150,
            ),
        },
    },
    computed: {
        noDataAvailable()
        {
            return 'Nothing found';
        },
        statsSorted()
        {
            const customerTypes = Object.values(this.stats.customerTypes).sort((a,b) => b['count'] - a['count']);
            const industries = Object.values(this.stats.industries).sort((a,b) => b['count'] - a['count']);
            const paymentSolutions = Object.values(this.stats.paymentSolutions).sort((a,b) => b['count'] - a['count']);
            const formSteps = Object.values(this.stats.formSteps);
            const offers = Object.values(this.stats.offers).sort((a,b) => b['count'] - a['count']);
            return {customerTypes: customerTypes, industries: industries, paymentSolutions: paymentSolutions, formSteps: formSteps, offers: offers};
        }
    },
    methods: {
        dump()
        {
            //console.log(this, this.$parent.auth);
        },
        getPaginationLabel(label)
        {
            const props = {
                label: label,
                isPrev: false,
                isNext: false,
            };
            if(label.toLowerCase().includes('previous'))
            {
                props.label = '';
                props.isPrev = true;
            }
            if(label.toLowerCase().includes('next'))
            {
                props.label = '';
                props.isNext = true;
            }
            return props;
        },
        formatDate(value){
            return formatDate(value);
        },
        setDates(dates)
        {
            if(dates && dates != -1)
            {
                this.options.dateStart = this.filters[dates].dateStart;
                this.options.dateEnd = this.filters[dates].dateEnd;
            }
            else
            {
                this.options.dateStart = -1;
                this.options.dateEnd = -1;
            }
        }
    },
};
</script>

<template>
    <Head title="ngebotsfinder"/>
	<h1>Angebotsfinder</h1>
	
    <div class="filters">
        <div class="row">
			<div class="col-12">
				<span>Filter nach Zeitraum:</span>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-auto">
						<label for="date-start" class="form-label">Von:</label>
						<input type="date" class="form-control" v-model="options.dateStart" id="date-start">
					</div>
					<div class="col-auto">
						<label for="date-end" class="form-label">Bis:</label>
						<input type="date" class="form-control" v-model="options.dateEnd" id="date-end" :min="options.dateStart">
					</div>
					<div class="col-auto">
						&nbsp;<br/>
						<button class="btn fs-6" @click="setDates(-1)">Datum zurücksetzen</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="row">
					<div class="col-12">
						<label for="quick-select-week">Schnellauswahl:</label>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" id="quick-select-week" @click="setDates('lastWeek')">Letzte Woche</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('currentMonth')">Aktueller Monat</button>
					</div>
					<div class="col-auto">
						<button class="btn fs-6" @click="setDates('lastMonth')">Letzter Monat</button>
					</div>
				</div>
			</div>           
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Filter nach Kundentyp:</span>
        </div>
        <div class="col-auto" v-for="customerType in customerTypes">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'customerType-' + customerType.id" name="customerTypes" :value="customerType.id" v-model="options.customerTypes"/>
                <label class="form-check-label" :for="'customerType-' + customerType.id">{{customerType.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12">
            <span>Sonstige Filter:</span>
        </div>
		<div class="col-auto" v-for="specialCriteria in specialCriterias">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'specialCriteria-' + specialCriteria.id" name="specialCriterias" :value="specialCriteria.id" v-model="options.specialCriterias"/>
                <label class="form-check-label" :for="'specialCriteria-' + specialCriteria.id">{{specialCriteria.name}}</label>
            </div>
        </div>
    </div>
    <div class="row mb-3 align-items-center">
        <div class="col-4">
			<div class="row">
				<div class="col-auto">
					<span class="text-sm">Anzahl:&nbsp;{{total}}</span>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row justify-content-center">
				<div class="col-auto">
					&nbsp;
				</div>
			</div>
        </div>
		<div class="col-4">
			<div class="row float-end">
				<div class="col-auto">
					<label class="form-label" for="limit">Einträge pro Seite:</label>
					<select id="limit" class="form-select" v-model="options.limit">
						<option :value="option.limit" v-for="option in limits">{{option.label}}</option>
					</select>
				</div>
			</div>
        </div>
    </div>
    <div class="table-responsive" v-if="calculations.data.length > 0">
        <table class="table table-striped mb-3">
            <thead>
				<tr>
					<th>Erstellt am</th>
					<th>Kunden-Typ</th>
					<th>Branche</th>
					<th>Bezahllösung</th>
					<th>Durschnittsbon</th>
					<th>Anzahl Kunden</th>
					<th>monatl. Umsatz</th>
					<th>Empfehlung 1</th>
					<th>Empfehlung 2</th>
					<th>MQL</th>
				</tr>
            </thead>
            <tbody>
				<tr v-for="calculation in calculations.data">
					<td><span :title="'ID: '+calculation.id+', Footprint: '+calculation.footprint_id">{{ formatDate(calculation.created_at) }}</span></td>
					<td>{{ calculation.customerTypeFormatted }}</td>
					<td>{{ calculation.industryFormatted }}</td>
					<td>{{ calculation.paymentSolutionFormatted }}</td>
					<td align="right">{{ calculation.average_receipt }}</td>
					<td align="center">{{ calculation.customers_per_day }}</td>
					<td align="right">{{ calculation.turnover_monthly }}</td>
					<td>{{ calculation.offer_recommendation_1 }}</td>
					<td>{{ calculation.offer_recommendation_2 }}</td>
					<td>
						<span v-if="calculation.isMQL === true" class="isMql">Ja</span>
						<span v-if="calculation.isMQL === false" class="isNoMql">Nein</span>
				</td>
				</tr>
            </tbody>
        </table>
    </div>

    <h3 v-else>{{noDataAvailable}}</h3>
    <nav v-if="calculations.links.length > 3">
        <ul class="pagination flex-wrap">
            <template v-for="link in calculations.links">
                <li class="page-item" :class="{'disabled': link.url === null, 'active': link.active, 'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}">
                    <Link
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        :href="link.url"
                        v-html="getPaginationLabel(link.label).label"
                        v-if="link.url !== null">
                    </Link>
                    <span
                        class="page-link"
                        :class="{'prev': getPaginationLabel(link.label).isPrev, 'next': getPaginationLabel(link.label).isNext}"
                        v-else
                        v-html="getPaginationLabel(link.label).label">
                            </span>
                </li>
            </template>
        </ul>
    </nav>
	<br/>
	<br/>
	<div v-if="calculations.data.length > 0">
	
		<h2>Statistiken ingesamt</h2>
		<br/>
		<div class="row justify-content-center">
			<div class="col-4">
				<p><strong>Kunden-Typ</strong></p>
				<pie-chart :items="statsSorted.customerTypes"></pie-chart>
			</div>
			<div class="col-4">
				<p><strong>Branche</strong></p>
				<pie-chart :items="statsSorted.industries"></pie-chart>
			</div>
			<div class="col-4">
				<p><strong>Bezahllösung</strong></p>
				<pie-chart :items="statsSorted.paymentSolutions"></pie-chart>
			</div>
		</div>
		<br/>
		<div class="row justify-content-center">
			<div class="col-12">
				<p><strong>Formular-Schritte</strong></p>
				<bar-chart :items="statsSorted.formSteps"></bar-chart>
			</div>
		</div>
		<br/>
		<div class="row justify-content-center">
			<div class="col-12">
				<p><strong>Angebote</strong></p>
				<bar-chart :items="statsSorted.offers"></bar-chart>
			</div>
		</div>
		<br/>
		<br/>
	</div>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";



</style>
