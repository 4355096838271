<template>
    <nav class="navbar navbar-expand-xl bg-light p-0 mb-3">
        <div class="container-fluid">
            <Link class="navbar-brand" href="/dashboard">
                <logo/>
            </Link>
            <main-menu-plain></main-menu-plain>
        </div>
    </nav>
    <div class="container-fluid">
        <flash-messages/>
        <slot/>
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3';
import Icon from '@/Shared/Icon';
import Logo from '@/Shared/Logo';
import Dropdown from '@/Shared/Dropdown';
import MainMenuPlain from '@/Shared/MainMenuPlain';
import FlashMessages from '@/Shared/FlashMessages';

export default {
    components: {
        Dropdown,
        FlashMessages,
        Icon,
        Link,
        Logo,
        MainMenuPlain,
    },
    props: {
        auth: Object,
    },
    methods: {
        dump()
        {
            console.log(this);
        },
    },
};
</script>
<style scoped lang="scss">
.navbar
{
    @media screen and (min-width: 992px)
    {
        position: sticky;
        top: 0;
        z-index: 100;
    }
}
</style>
