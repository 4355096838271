<script>
import BackendLayout from '@/Layouts/BackendLayout.vue';
import {Head, Link} from '@inertiajs/inertia-vue3';
import Layout from '@/Shared/Layout';
import CreateButton from '@/Shared/CreateButton';
import Icon from "@/Shared/Icon.vue";
import {formatDate} from "@/custom";
import TextInput from "@/Shared/TextInput.vue";
import LoadingButton from "@/Shared/LoadingButton.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import TextareaInput from "@/Shared/TextareaInput.vue";
import Searchbox from "@/Shared/Searchbox.vue";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";

export default {
	components: {
		Searchbox,
		LoadingButton,
		TextInput,
		Icon,
		CreateButton,
		BackendLayout,
		Link,
		Head,
		SearchFilter,
		TextareaInput
	},
	layout: Layout,
	props: {
		entries: Object,
		ad_platforms: Array,
		search: String,
		//test: Object
	},
	data() {
		return {
			form: this.$inertia.form(
				{
					email: '',
					reason: '',
					ad_platforms: this.ad_platforms.map(platform => platform.id)
				},
			),
			searchForm: this.$inertia.form(
				{
					query: '',
				},
			),
			options: {
				search: this.search
			},
			selectedEntry: null,
			editMode: false,
			sendingEdit: false,
		};
	},
	watch: {
		options: {
			deep: true,
			handler: throttle(
				function(oldVal, newVal)
				{
					if(this.options.search && this.options.search.length < 3) return; // refactoring needed for other options!
					this.$inertia.get(route('insights.blacklist'), pickBy(this.options), {preserveState: true});
				},
				500,
			),
		},
	},
	mounted() {
		//console.log(this.test)
		//console.log(this.entries, this.ad_platforms)
	},
	methods: {
		formatDate(value) {
			return formatDate(value);
		},
		store() {
			this.form.post(route('insights.blacklist.store'), {
				onSuccess: () => {
					this.form.reset();
					//this.form.reason = ''
				},
				onError: errors => {
				},

			});
		},
		update(entry, cancel = false) {

			this.editMode = !cancel;

			const submit = this.selectedEntry?.id === entry.id && !cancel;
			const resetFormerEdit = this.selectedEntry && this.selectedEntry.id !== entry.id || cancel;

			if(resetFormerEdit)
			{
				this.$inertia.get(route('insights.blacklist'), pickBy(this.options), {preserveState: true});
				/*
				this.entries.data = this.entries.data.map(orgEntry => {
					if(orgEntry.id === this.selectedEntry.id)
					{
						//orgEntry = JSON.parse(JSON.stringify(this.selectedEntry));

					}
					//console.log(orgEntry)
					return orgEntry;
				})

				 */
			}
			if(cancel)
			{
				this.selectedEntry = null;
				this.editMode = this.sendingEdit = false;
				return;
			}
			this.selectedEntry = JSON.parse(JSON.stringify(entry));
			if(!submit)
			{
				return;
			}
			//this.sendingEdit = true;
			//submit
			this.$inertia.put(route('insights.blacklist.update', { blacklistEntry: entry.id }), entry,{
				onFinish: () => {
					this.selectedEntry = null;
					this.editMode = this.sendingEdit = false;
				},

			})
			//console.log(this.selectedEntry)

		},
		search() {

		},
		destroy(id) {
			this.$inertia.delete(route('insights.blacklist.destroy', {blacklistEntry: id}))
		},
		exportBlacklist()
		{
			window.location.href = route('insights.blacklist.export');
		},
		isEditingDisabled(entry)
		{
			return !this.editMode || (this.editMode && this.selectedEntry.id !== entry.id);
		}
	},
	computed: {

	}
}
</script>

<template>
	<Head title="Blacklisted e-mails"/>
	<h1>Blacklisted e-mails</h1>
	<div class="rounded shadow p-3 mb-5">
		<form class="row" @submit.prevent="store">
			<div class="col-12">
				<text-input v-model="form.email" type="email" :error="form.errors.email" name="email" class="mb-3"
							label="E-mail" :required="true"/>
			</div>
			<div class="col-12">
				<div class="form-floating mb-3">
					<textarea
						class="form-textarea form-control"
						name="reason"
						placeholder=" "
						v-model="form.reason"
						id="new-blacklist-entry-reason"
					>
					</textarea>
					<label class="form-label" for="new-blacklist-entry-reason">Begründung</label>
				</div>
				<!--
				<textarea-input v-model="form.reason" class="mb-3" name="reason" label="Begründung"/>
				-->
			</div>
			<div class="col-auto" v-for="platform in ad_platforms">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" :id="'cb-apf-' + platform.id" name="ad_platforms"
						   :value="platform.id" v-model="form.ad_platforms"/>
					<label class="form-check-label" :for="'cb-apf-' + platform.id">{{ platform.name }}</label>
				</div>
			</div>

			<div class="col-auto">
				<loading-button :loading="form.processing" class="btn primary action" type="submit">Add e-mail
				</loading-button>
			</div>

		</form>
	</div>
	<div class="row justify-content-center">
		<div class="col-auto">
			<a :href="route('insights.blacklist.export')" class="btn fs-6" target="_blank">
				<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="48"><path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z"/></svg>
				Export
			</a>
		</div>
	</div>
	<div class="table-responsive">
		<table class="table table-striped mb-3">
			<thead class="blacklist-entries-table-head">
			<tr>
				<th>
					E-Mail
					<Searchbox placeholder="Filter..." :show-search-button="false" v-model="options.search" class="d-inline-block" v-if="options.search || entries.data.length > 0"></Searchbox>
				</th>
				<th>Plattform</th>
				<th>Grund</th>
				<th>Erstellt am</th>
				<th>Aktionen</th>
			</tr>
			</thead>
			<tbody v-if="entries.data.length > 0">
			<tr v-for="entry in entries.data" class="blacklist-entry">
				<td>{{ entry.email }}</td>
				<td>
					<template v-for="platform in ad_platforms">
					<div
						:class="{ 'form-check edit-inline': true, 'disabled': isEditingDisabled(entry) }"

						v-if="!isEditingDisabled(entry) || entry.ad_platforms.includes(platform.id)"
					>
						<input class="form-check-input" type="checkbox" :id="'cb-apf-inline-' + platform.id + '_' + entry.id"
							   name="ad_platforms"
							   :value="platform.id" v-model="entry.ad_platforms"
							   :disabled="isEditingDisabled(entry)"/>
						<label class="form-check-label" :for="'cb-apf-inline-' + platform.id + '_' + entry.id">{{
								platform.name
							}}</label>
					</div>
					</template>
				</td>
				<td>
					<div class="edit-inline">
						<textarea
							class="form-textarea form-control"
							name="reason"
							placeholder=" "
							:disabled="isEditingDisabled(entry)"
							v-model="entry.reason"
						>
						</textarea>
					</div>
				</td>
				<td><span :title="'ID: '+entry.id">{{ formatDate(entry.created_at) }}</span></td>
				<td class="entry-actions" style="width: 150px;">
					<button class="btn text-danger action" type="button"
							data-bs-toggle="modal"
							data-bs-target="#delete-entry-confirmation"
							v-if="isEditingDisabled(entry)"
							@click="selectedEntry=entry"
							title="Delete entry">
						<icon name="trash" width="24" height="24"/>
					</button>
					<button class="btn text-danger action" type="button"
							v-if="!isEditingDisabled(entry)"
							@click="update(entry, true)"
							:disabled="sendingEdit"
							:title="'Cancel'">
						<icon :name="'cancel'" width="24" height="24"/>
					</button>
					<button class="btn text-primary action" type="button"
							@click="update(entry)"
							:disabled="sendingEdit"
							:title="isEditingDisabled(entry) ? 'Edit entry' : 'Save'">
						<icon :name="isEditingDisabled(entry) ? 'edit' : 'save'" width="24" height="24"/>
					</button>
					<!--
					<button class="btn text-primary action"
							v-if="editMode && selectedEntry.id === entry.id"
							type="button"
							:disabled="sendingEdit"
							@click="update"
							title="Save">
						<icon name="accepted" width="24" height="24"/>
					</button>
					-->
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<div class="modal" tabindex="-1" id="delete-entry-confirmation">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Delete Entry</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<p>Do you really want to delete {{ selectedEntry?.email }}?</p>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" class="btn action primary" data-bs-dismiss="modal">Cancel</button>
					<button type="button" class="btn action danger" data-bs-dismiss="modal"
							@click="destroy(selectedEntry?.id)">Yes
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "resources/sass/partials/variables";

.entry-actions {
	.btn {
		&.action {
			box-shadow: none;
			background: none;
			//padding: 0;
			//position: absolute;
			//top: 0;
			//right: 0;
		}
	}
}
.blacklist-entries-table-head
{
	th
	{
		vertical-align: middle;
	}
}
.blacklist-entry
{
	td
	{
		vertical-align: middle;
	}
	::v-deep(.edit-inline)
	{
		textarea
		{
			min-height: auto;
			height: auto;
			padding: .5rem;

			&:disabled
			{
				padding: 0;
				border: 0;
				background-color: transparent;
				resize: none;
			}
		}
		.form-check-input
		{
			&:disabled
			{
				display: none;
			}
		}
		&.disabled
		{
			display: inline-block;
			padding: 0;
			margin-right: 15px;
		}
	}

}
</style>
